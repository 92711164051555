/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 .MyAccountNewReturn {
     padding-block-end: 25px;
     border-radius: 4px;
     
     @include desktop {
        padding-inline: 25px;
    }

    .amrma-attach {
        display: none;
    }

    &-CustomerAndAddressBlocks {
        display: flex;
        font-size: 14px;
        min-height: 200px;
        flex-direction: column;


        > * {
            @include desktop {
                flex: 1;
            }
        }
        .MyAccountDashboard-DefaultAddress{
            justify-content: left;
            margin-inline-start: 0;

            ul{
                li{
                    &:before{
                        display: none;
                    }
                }
            }
        }
    }

    &-Actions {
        margin-top: 30px;

        @include before-desktop {
            display: flex;
        }

        @include desktop {
            margin-top: 30px;
        }

        button {
            display: inline-block;

            &:first-of-type {
                margin-right: 10px;

                @include before-desktop {
                    flex: 1
                }

                @include desktop {
                    margin-right: 20px;
                    max-width: 350px;
                    width: 100%;
                }
            }

            &:last-of-type {
                @include before-desktop {
                    width: max-content;
                }
            }
        }
    }

    &-MessageTitle {
        margin-top: 20px;
        text-transform: uppercase;
        font-size: 22px;
        font-weight: 700;
    }

    &-MessageAdditionalInfo {
        margin: 5px 0;
        font-style: italic;

        @include desktop {
            margin-top: 15px;
        }
    }

    &-MessageTextArea {
        textarea {
            font-size: var(--font-size);
            height: 120px;
            width: 100%;

            @include after-mobile {
                height: 150px;
            }
        }
    }

    &-MessageAttachmentButton {
        align-items: center;
        display: flex;
        font-size: var(--font-size);
        margin-top: 10px;

        svg {
            height: 20px;
            margin-right: 5px;
            width: 20px;
        }
    }

    &-CustomerAndAddressBlocks {
        display: flex;

        @include before-desktop {
            flex-direction: column;
            .MyAccountDashboard-DefaultAddress{
                padding-left: 0 !important;
                margin-block-start: 15px;
            }
        }

        > * {
            @include desktop {
                flex: 1;
            }
            p{
                margin-bottom: 4px;

                @include mobile {
                    font-size: 12px;
                }
            }
        }

        >:first-child {
            @include before-desktop {
                margin-bottom: 10px;
            }

            @include desktop {
                margin-right: 10px;
            }
        }
    }

    .MyAccount-Heading {
        @include mobile {
            font-size: 17px;
            margin-top: 14px;
        }
    }

    &-Wrapper {
        padding-block: 15px;

        @include desktop {
            padding-block: 60px;
        }

    }

    &-Heading {
        font-size: 16px;
        font-weight: 700;
        
        @include desktop {
            padding-block-end: 10px;
            font-size: 24px;
        }

        @include mobile {
            margin-block-end: 10px;
        }
    }

    .Policy {
        &-Wrapper {
            margin-block-start: 25px;

            p {
                font-size: 12px;
                color: $light-primary-color;
                strong {
                    display: block;
                }

                a {
                    font-weight: 600;
                    color: inherit;
                    font-size: inherit;
                    text-decoration: underline;
                }
            }
        }
    }
}

.MyAccountNewReturn-CustomerAndAddressBlocks{
    .MyAccountDashboard-DefaultAddress{
        .MyAccountAddressTable{
            h4{
                font-weight: 700;
                color: $default-primary-base-color;
                margin-bottom: 5px;
            }
            span{
                line-height: 22px;
            }
        }
    }
}