/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountMyWishlist {
    &-Products {
        @include wide-desktop {
            grid-template-columns: repeat(3, 1fr);
        }

        @include mobile {
            display: flex;
            flex-wrap: wrap;
            grid-gap: 16px;
        }

        // > div {
        //     width: calc(50% - 8px);
        // }
        
        .WishlistItem {
            &-FigureWrapper {
                padding: 0;
                padding-block-end: 10px;

                @include mobile {
                    width: auto;
                    display: flex;
                    flex-direction: column;
                }

                h4 {
                    @include mobile {
                        font-weight: 600;
                        font-size: 12px;
                    }
                }
            }

            &-Name {
                h4 {
                    font-weight: 600;
                }

                .WishlistItemOptions-List {
                    display: none;
                }
            }

            &-Content {
                padding-inline: 0;
                position: static;
            }

            &-RowWrapper {
                margin-block-start: 0;
            }

            &-AddToCart, &-RemoveLabel, &-Edit {
                display: none;
            }

            &-ActionWrapper {
                position: absolute;
                inset-block-start: 0;
                inset-inline-end: 0;

                .CloseIcon {
                    width: 14px;
                    height: 14px;
                }
            }

            &-Price {
                .ProductPrice {
                    &-PriceValue {
                        color: $default-primary-base-color;
                    }
                }
            }

            &-ImageWrapper {
                @include mobile {
                    width: 100%;
                    margin-inline-end: 0;
                }
            }

            &-InformationWrapper {
                .WishlistItemOptions-List {
                    display: none;
                }
            }
        }
    }

    &-Topdesc {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-block: 10px 20px;
        margin-block-end: 30px;
        border-bottom: 1px solid $default-primary-base-color;
        
        @include mobile {
            padding-block-start: 0;
            border-bottom: 1px solid $border-color;
            padding-block-end: 30px;
        }

        .desc {
            @include desktop {
                width: 50%;
            }

            p {
                font-size: 12px;
                
                @include mobile {
                    margin-block-end: 0px;
                }

                svg {
                    margin-inline: 5px;
                }
            }
        }
    }

    &-ActionBar {
        position: relative;
        inset-block-start: auto;

        @include mobile {
            border-top: 0;
            padding: 0px;
            padding-inline: 0;
        }

        Button {
            @include mobile {
                margin-block-start: 20px;
            }
        }
    }

    &-ClearWishlistButton {
        &.Button {
            border: 1px solid $default-primary-base-color;
            font-size: 12px;
            font-weight: 600;
            text-transform: none;
            
            @include mobile {
                width: 100%;
            }

            &:disabled {
                display: none;
            }
        }
    }

    .Wishlist {
        &-Sort {
            display: flex;
            margin-block-end: 25px;
            
            @include desktop {
                justify-content: flex-end;
                align-items: center;
            }

            @include mobile {
                flex-direction: column-reverse;
                justify-content: center;
            }

            .Field {
                margin-block-start: 0;
                min-width: 200px;

                label.Field-Label {
                    position: absolute;
                    inset-block-start: -5px;
                }
            }

            .count {
                font-weight: 700;
                font-size: 14px;
                
                @include desktop {
                    margin-inline-end: 15px;
                }

                @include mobile {
                    text-align: center;
                    margin-block-start: 20px;
                }
            }

            .Wishlist-Select {
                .FieldSelect {
                    &-Select {
                        font-weight: 600;
                        border-color: $default-primary-base-color;
                    }

                    &-Options {
                        border-block-start: none;
                        
                        &_isExpanded {
                            border-color: $default-primary-base-color;
                        }

                        li {
                            &:first-child {
                                margin-block-start: 10px;
                            }
                        }
                    }

                    &-Option {
                        font-size: 12px;
                        line-height: 28px;
                    }
                }
            }
        }
    }
}
