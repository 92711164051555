/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountDashboard {
    display: block;
    
    @include mobile {
        margin-block-end: 30px;
    }

    .Account {
        &-Header {
            display: flex;
            justify-content: space-between;
            margin-block-end: 15px;

            p {
                @include desktop {
                    font-size: 16px;
                }
            }

            .Button {
                @include desktop {
                    min-width: 212px;
                    margin-inline-start: 20px;
                }

                @include mobile {
                    padding-inline: 15px;
                    width: 180px;
                    font-size: 12px;
                    margin-inline-start: 10px;
                }
            }
        }
    }

    .pick-for-you-slider {
        padding-block-start: 15px;

        @include desktop {
            padding-block-start: 30px;
        }

        .Heading {
            font-size: 24px;
            margin-block-end: 20px;
            letter-spacing: 1px;

            @include mobile {
                font-size: 20px;
                margin-block-end: 10px;
            }
        }
    }

    .ProductSlider {
        &-Wrapper {
            padding-inline: 0;
            margin-inline: -10px;
            width: calc(100% + 20px);

            .SlickSlider {
                padding-inline: 0;
            }
        }
    }

    .CustomerPreferences {
        &-Interests {
            display: flex;
        }
    }
}
