/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.MyAccountMyReturns {
    @include mobile {
        margin-top: 10px;
        overflow-x: auto;
        width: 100%;
    }

    &-New {
        margin-bottom: 15px;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
        min-height: 200px;
        .CreateHeading{
            flex: 0 0 100%;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 5px;
        }
    }

    &-DropdownWrapper {
        display: flex;
        flex-wrap: wrap;

        @include desktop {
            width: 40%;
        }

        button {
            flex: 100%;
            margin-block-start: 15px;
        }

        .Field-Wrapper_type_select {
            width: 100%;
        }
    }

    .ProductTabs {
        display: flex;
        border-block-end: 1px solid $border-color;
    
        @include desktop {
            display: flex;
        }
    
        &-Wrapper {
            padding-inline: 0;

            @include mobile {
                padding: 0;
            }
    
            .ExpandableContent-Heading {
                text-transform: uppercase;
            }
    
            .ExpandableContent {
                border-block-end: 0;
            }
    
            & > :first-child {
                border-block-start: 0;
            }
        }

        .ProductTab {
            &-Item {
                color: $default-primary-base-color;
                text-decoration: none;
                letter-spacing: 1/2px;
                padding: 20px 0;
                margin-block-end: 0;

                @include desktop {
                    margin-inline-end: 50px;
                }

                @include mobile {
                    flex: 1 1;
                    text-align: center;
                }

                &::before {
                    display: none;
                }

                &_isActive {
                    background-color: transparent;

                    &::after {
                        content: '';
                        position: absolute;
                        inset-block-end: 0;
                        width: 100%;
                        height: 2px;
                        background-color: $default-primary-base-color;
                        inset-inline-start: 0;
                    }
                }
            }

            a {
                font-size: 16px;
            }

            &-Button {
                color: $default-primary-base-color;
                text-decoration: none;
                letter-spacing: 0.25;
                padding: 0;
                font-size: 16px;

                @include mobile {
                    width: 100%;
                }

                a {
                    font-size: inherit
                }
            }
        }
    }

    .MyAccountMyOrders-Msg {
        font-size: 16px;
        margin-bottom: 0;
    }
}


.MyAccountMyOrders {
    &-Table {
        font-size: 14px;
        width: auto;
        min-width: 100%;
        max-width: 100%;
        th{
            background: $white;
            border-bottom: solid 1px $border-color;
            height: 40px;
            vertical-align: middle;
            font-size: 14px;
            padding: 10px;
        }
    }
}

@media screen and (max-width: 700px) {
    table.MyAccountMyOrders-Table {
      border: 0;
    }
    
    table.MyAccountMyOrders-Table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    
    table.MyAccountMyOrders-Table tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: .625em;
    }
    
    table.MyAccountMyOrders-Table td {
        padding: 5px 0;
      display: block;
      text-align: right !important;
      width: 100% !important;
      position: relative;
      overflow: hidden;
      > button, a{
          float: right;
          clear: both;
      }
    }

    table.MyAccountMyOrders-Table tr{
        margin-bottom: 15px;
        padding-bottom: 30px;
        height: auto;
    }

    table.MyAccountMyOrders-Table td div.OrderImage{
        margin-left: auto;
        justify-content: flex-end;
        flex-wrap: wrap;
        align-items: center;
        img{
            margin-right: 0;
            margin-block-end: 10px;
        }
        > span{
            flex: 0 0 100%;
        }
    }
    
    table.MyAccountMyOrders-Table td::before {
      content: attr(data-label);
      float: left;
      font-weight: 700;
      top: 50%;
      position: absolute;
      left: 0;
      transform: translateY(-50%);
    }
    
    table.MyAccountMyOrders-Table td:last-child {
      border-bottom: 0;
    }
  } 


  @media screen and (max-width: 960px) {
    .MyAccountMyReturns-Content {
        nav {
            .Pagination {
                display: flex;
                margin-top: 0;
                margin-bottom: 30px;
            }
        }
    }
  }
