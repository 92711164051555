/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

$white: #fff;
$black: #000000;
$default-primary-base-color: #1D1D1D;
$defult-primary-background-color:#1D1D1D;
$default-primary-dark-color: darken($default-primary-base-color, 15%);
$default-primary-light-color: lighten($default-primary-base-color, 15%);
$default-secondary-base-color: #F0F0F0;
$default-secondary-dark-color: darken($default-secondary-base-color, 15%);
$default-secondary-light-color: lighten($default-secondary-base-color, 15%);
$btn-primary-color:#5F5F5F;
$light-primary-color:#5F5F5F;
$btn-primary-background-color:#fff;
$drakGray-color:#949494;
$font-muli: "NeuePlak";
$font-NeuePlak: "NeuePlak";
$font-NeuePlakBlack-Condensed: 'Neue Plak Condensed';
$font-NeuePlakRagular-Condensed: 'Neue Plak Condensed';
$font-NeuePlak-Condensed-Black: 'Neue Plak Condensed Black-1';
 
$font-standard-size: 62.5%;
$addtobag-backgorund-color:#DC0019;
$discountPrice-color:#DC0019;
$border-color:#D0D0D0;
$review-start-background: #C4C4C4;
$footer-background:#F0F0F0;
