/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountTabList {
    @include desktop {
        border: 0;
        padding-block-start: 25px;
        width: 310px;
    }

    &-ExpandableContentButton {
        @include mobile {
            padding: 25px 0;
            border-bottom: 1px solid $border-color;
            display: flex;
            justify-content: center;
            width: 100%;
            align-items: center;
        }

        .DownArrow, .ChevronIcon {
            margin-inline-start: 15px;
            width: 14px;
            margin-block-start: -2px;
        }
    }

    .ExpandableContent {
        &-Heading {
            font-weight: 400;
        }
    }
}
