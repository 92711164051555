/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 .MyAccountReturnDetails {
    font-size: var(--font-size);
    border: 1px solid $border-color;
    padding-inline: 25px;
    padding-block-end: 25px;
    border-radius: 4px;

    &-Heading {
        border-bottom: 1px solid $border-color;
        font-size: 28px;

        @include mobile {
            font-size: 22px;
            padding-block-end: 5px;
        }
    }

    &-ExpandableContentHeading,
    &-SubTitle {
        text-transform: none;
        font-weight: normal;
        line-height: 1;
        font-size: 17px;

        @include after-mobile {
            font-size: 20px;
        }
    }

    &-Status{
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 15px;
        h4{
            font-weight: 700;
        }
    }

    &-CustomerAndAddressBlocks {
        display: flex;

        .MyAccountDashboard-DefaultAddress{
            padding: 0 !important;
            margin: 0 !important;
            width: 100%;
            justify-content: flex-end;
            h4{
                font-weight: 700;
            }
            ul{
                li{
                    &:before{
                        display: none;
                    }
                }
            }
        }

        @include before-desktop {
            flex-direction: column;
        }

        > * {
            @include desktop {
                flex: 1;
            }
        }

        >:first-child {
            @include before-desktop {
                margin-bottom: 15px;
            }

            @include desktop {
                margin-right: 15px;
            }
        }
    }

    .MyAccountNewReturnItemSelect-Title {
        margin-top: 30px;

        @include after-mobile {
            margin-top: 30px;
        }
    }

    .MyAccount-Heading {
        @include mobile {
            display: block;
            margin: 0 0 10px;
            margin-top: 14px;
            font-size: 17px;
        }
    }

    .ExpandableContent {
        &-Button {
            &::after {
                align-items: center;
                bottom: 0;
                content: '\002B';
                display: flex;
                pointer-events: none;
                position: absolute;
                right: 15px;
                top: 50%;
                transform: translateY(-50%);
            }

            &_isContentExpanded::after {
                content: '\002D';
            }
        }
    }

    &-Wrapper {
        padding-block: 30px;
    }
}