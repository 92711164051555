/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountOrderTotals {
    &-Wrapper {
        background-color: transparent;

        @include mobile {
            margin-block-start: 35px;
            margin-block-end: 50px;
        }
    }

    &-Title {
        font-size: 16px;
        font-weight: 700;
        line-height: 21px;
        margin-block-end: 10px;
    }

    &-Subtotal {
        @include mobile {
            display: flex;
            justify-content: space-between;
        }

        span {
            font-size: 12px;
            line-height: 20px;

            &.label {
                min-width: 130px;
                display: inline-block;
            }
        }
    }

    &-TotalPrice {
        margin-block-start: 25px;
        border-top: 1px solid $border-color;
        padding-block-start: 25px;

        span {
            font-size: 14px;
            font-weight: 600;
        }
    }
}
