/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountInformation {
    &-Wrapper {
        @include mobile {
            margin-block-end: 30px;
        }

        .InformationForMobile-Svg {
            @include mobile {
                text-align: center;
                padding-block-start: 40px;
                padding-block-end: 16px;
            }
        }

        .Form, .FieldForm {
            &-Fields {
                flex-direction: column;
            }

            .FieldForm {
                &-Section {
                    width: 100%;

                    .Information-Svg {
                        position: absolute;
                        inset-inline-start: 0;
                        inset-block-start: 85px;
                    }
                }

                &-Legend {
                    font-size: 16px;
                    padding-block-end: 15px;
                    font-weight: 600;
                    border-color: $default-primary-base-color;
                }
            }
        }

        .Information {
            &-Customer {
                padding-block-start: 15px;
                display: flex;
                flex-wrap: wrap;

                @include desktop {
                    width: 90%;
                    margin-inline-start: auto;
                }

                h3 {
                    font-size: 16px;
                    font-weight: 600;

                    @include desktop {
                        margin-block-end: 5px;
                        line-height: 40px;
                    }
                }

                .Field-Wrapper {
                    width: 100%;

                    @include desktop {
                        width: calc(50% - 16px);
                    }

                    .Field {
                        @include desktop {
                            margin-inline-end: 20px;
                        }

                        &-Label {
                            font-weight: 600;
                        }

                        input {
                            font-weight: 600;
                        }

                        .FieldSelect-Select {
                            font-weight: 600;
                        }
                    }

                    &_type_text, &_type_date, &_type_select {
                        @include desktop {
                            margin-inline-end: 45px;
                        }
                    }

                    &_type_checkbox {
                        width: auto;

                        .input-control {
                            opacity: 0;
                            display: none;
                        }
                    }

                    .changeEmail {
                        position: absolute;
                        inset-block-start: 30px;
                        inset-inline-end: 20px;
                    }

                    .changePassword {
                        font-size: 14px;
                        font-weight: 600;

                        @include desktop {
                            margin-block-start: 54px;   
                        }
                    }

                    &_type_email, &_type_checkbox {
                        & + .Field-Wrapper_type_text {
                            display: none;
                        }
                    }
                }

                .changePassword_isdefaultChecked {
                    display: none;
                }
            }

            &-Title {
                width: 100%;

                @include mobile {
                    margin-block-end: 0;
                }
            }
        }
        
        .ChangePassBtn, .ChangeEmailBtn {
            text-align: end;
            margin-block-start: 20px;
            
            button {
                font-weight: 600;
                cursor: pointer;
                font-size: 14px;
            }
        }    
    }

    &-Submit {
        @include desktop {
            float: inline-end;
            min-width: 220px;
        }
    }
}
