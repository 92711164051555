/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.SignInForm {
    .MyAccountOverlay {
        &-Additional {
            &_isActive {
                display: none;
            }
        }
    }
}

.MyAccountOverlay {
    &-LeftAttempt {
        padding-block-start: 5px;
        font-size: 12px;
        color: red;
    }
}

.Password-Wrapper {
    position: relative;

    .showHide-btn {
        position: absolute;
        width: 30px;
        height: 16px;
        inset-inline-end: 15px;
        inset-block-start: 32px;
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 16px;
        /* identical to box height */
        text-align: end;
        color: #1d1d1d;
        cursor: pointer;
    }
}


.login-top-batch {
    margin: 10px 0 0 0;

    p {
        margin: 0;
        font-size: 11px;
        font-weight: 400;
        color: #000;
    }
}
