/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/* stylelint-disable declaration-no-important */

.Header {
    .MyAccountOverlay {
        border-radius: 0 0 4px 4px;
        border: 1px solid $black;
        border-top-color: $white;
        overflow: hidden;

        &::before {
            display: none;
        }

       @include desktop {
           right: 20px !important;
           width: auto;
           top: calc(var(--header-nav-height) - 19px);
       }    

       h4 {
           font-size: 16px;
       }

       ul {
           li {
               margin-block-start: 8px;

            &::before {
                display: none;
            }   

               a {
                   font-weight: 600;
                   font-size: 12px;
                   color: $light-primary-color;

                   &:after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    transform: scaleX(0);
                    height: 1px;
                    bottom: -3px;
                    background-color: $default-primary-base-color;
                    transform-origin: bottom right;
                    transition: transform 0.25s ease-out; 
                    left: 0;                               
                   }

                   &:hover {
                       &:after {
                           transform: scaleX(1);
                           transform-origin: bottom left;
                       }
                   }
               }

               &:last-child {
                   margin-block-end: 6px;
               }
           }
       }

       &-Action {
            &_state {
                &_loggedIn {
                    @include desktop {
                        width: 162px;
                        padding: 30px;
                        max-height: none;
                    }
                }
            }
       }
    }
}