/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable declaration-no-important */

.MyAccountAddressBook {
    @include desktop {
        // grid-template-columns: 1fr 1fr;
        // padding-inline-end: 20%;
    }

    &-List {
        @include desktop {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 24px;
            padding-inline-end: 15%;
        }
    }

    @include mobile {
        display: block;
    }

    &-Button {
        grid-column: 2 /-1;
        order: 1;
        inset-block-start: auto;
        position: relative;
        margin-inline-start: auto;
        
        @include desktop {
            width: 212px;
        }

        @include mobile {
            margin-block-end: 50px;
        }
    }

    .MyAccount-Heading {
        display: block !important;
        margin-block-end: 10px;
        
        @include mobile {
            display: none;
        }
    }
}

.MyAccountAddressPopup {
    .Popup-Content {
        @include desktop {
            width: 650px;
        }
    }

    .Field_type_checkbox label:first-of-type {
        margin-block-start: 10px;
    }
}
