/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountCreateAccount {
    // TODO style the element
}

.register-top-batch {
    margin-block-start: 15px;

    p {
        font-size: 11px;
        font-weight: 400;

        &.color-green {
            color: #000;
        }
    }

    .regsiter-process {
        margin-block-start: 20px;

        li {
            display: flex;
            margin-block-start: 15px;
            align-items: flex-start;
            font-size: 11px;
            
            &:last-child {
                margin-block-end: 0;
            }

            img {
                width: 18px;
                margin-inline-end: 15px;
            }
        }
    }
}

.Subscribe-Wrapper {
    padding-block-start: 15px;

    &_isActive {
        display: none;
    }  
}
