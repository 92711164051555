/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --checkbox-label-color: #0a0a0a;
    --my-account-input-label-color: rgba(10, 10, 10, 0.59);
    --my-account-overlay-width: 100%;
    --my-account-overlay-border-width: 1px;
    --popup-bottom-offset: 40px;
    --popup-min-width: 450px;
}

.MyAccountPopup {
    --overlay-background: var(--overlay-content-background);

    justify-self: flex-end;
    grid-column: 3;
    inset-inline-end: 0;
    padding-block-start: 12px;

    @include mobile {
        padding-block-start: 0;
    }

    @include desktop {
        position: fixed;
        inset-block-start: 0;
        inset-inline-end: 0;
        padding: 0;
        width: 100%;
        inset-inline-start: 0;
        // display: block;
        overflow: auto;
        align-items: baseline;
    }
    
    .Terms-condition { 
        font-size: 11px;
        color: $btn-primary-color;
        margin-block-start: 35px;
        margin-block-end: 22px;
        line-height: 20px;
        
        @include mobile {
            margin-block-start: 10px;
            // text-align: center;
            // padding: 0 20px;
            margin-block-end: 10px;
        }

        a {
            font-size: inherit;
            font-weight: 400;
            color: inherit;
            text-underline-position: under;
            text-decoration: underline;
        }
    }

    .Field {
        margin-block-start: 15px;

        .Field-Label {
            font-size: 11px;
            font-weight: 400;
            color: $default-primary-base-color;
            padding-block-end: 0px;
        }

        &_type_otp {
            width: 49%;
            margin-inline-end: auto;
        }

        .Field {
            &-CheckboxLabel {
                font-size: 11px;
                color: $default-primary-base-color;
            }
        }
    }

    .Popup-mainWrapper {
        margin: 2rem auto;
        min-height: calc(100% - 4rem);
        display: flex;
        align-items: center;
        
        @include mobile {
            width: calc(100% - 20px);
        }
    }

    .Popup-Content {
        border-radius: 0;
        
        @include desktop {
            width: 450px;
            margin: 0 auto;
            max-height: none;
        }

        .Popup-Header {
            .Popup-Heading {
                display: none;
            }
            
            .Popup-CloseBtn {
                text-align: end;
            }
        }
    }

    &::before {
        display: none;
    }

    button {
        width: 100%;
        text-transform: capitalize;
        font-weight: 600;
        line-height: 16px;
        font-size: 12px;
        
        &.Button_likeLink {
            width: auto;
            min-width: auto;
        }
    }

    .MyAccountOverlay-SignUpButton,
    .TimerButton {
        // width: 365px;
    }

    &-ForgotPassword {
        width: auto;
        margin-block-start: 8px;
    }

    input {
        width: 100%;
        border-radius: 4px;
    }

    &-Heading {
        font-size: 24px;
        font-weight: 600;
        padding-block-start: 0;
        margin-block-end: 0;
        color: $default-primary-base-color;
        line-height: 31px;
        margin-block-start: -10px;

        @include mobile {
            padding-block-end: 10px;
            padding-inline: 0px;
            margin-block-start: 0;
        }
    }

    &-Notification {
        background-color: var(--primary-dark-color);
        padding: 24px;

        @include mobile {
            padding: 28px;
        }
    }

    &-Navigation {
        li {
            margin: 0;
            padding: 0;

            &::before {
                content: none;
            }

            &:last-child {
                margin-block-end: 0;
            }

            a {
                display: block;
            }
        }

        a,
        button {
            width: 100%;
            text-align: start;
            padding: 12px 24px;
            font-size: 14px;

            @include mobile {
                padding: 14px 28px;
                font-size: 17px;
            }

            &:hover {
                background-color: var(--color-cart-light);
                text-decoration: none;
            }

            &:focus {
                text-decoration: none;
            }

            @include desktop {
                padding: 6px;
            }
        }

        @include desktop {
            padding: 6px;
        }
    }

    &-Action form,
    &-Additional_state_signIn {
        @include mobile {
            // padding-block-start: 14px;
            // padding-inline: 14px;
        }
    }

    &-SignInLink {
        width: auto;
        margin-block-start: 0;
    }

    &-ResetPassword {
        width: 100%;
        margin-block-start: 24px;
    }

    &-Action {
        display: grid;
        grid-template-rows: max-content 1fr;

        &_state {
            &_createAccount {
                h4 {
                    padding-inline-end: 5px;
                    display: inline;

                    @include mobile {
                        padding-inline-end: 2px;
                        font-size: 12px;
                    }
                }

                h3 {
                    margin-block-end: 12px;

                    @include mobile {
                        margin-block-end: 14px;
                    }
                }

                .Field {
                    margin-block-start: 15px;

                    @include mobile {
                        margin-block-start: 10px;
                    }
                }

                @include mobile {
                    padding-block-start: 14px;
                }

                .Terms-condition {
                    @include desktop {
                        padding-inline-start: 50px;
                        padding-inline-end: 50px;
                        text-align: center;
                        line-height: 1.5em;
                        margin-block-end: 0 ;
                        margin-block-start: 25px;
                    }

                    @include mobile {
                        text-align: center;
                        padding: 0 20px;
                    }
                }
            }

            &_forgotPassword {
                h4 {
                    width: initial;
                }

                button {
                    font-weight: bold;
                }
            }

            &_signIn {
                h4 {
                    text-align: center;
                }

                .Form .Button_likeLink {
                    margin-block-start: 0px;
                    margin-block-end: 0px;
                    font-weight: 400;
                    font-size: 11px;
                    
                    @include desktop {
                        margin-block-start: 20px;
                    }
                }

                .Field_type_otp {
                    width: 100%;
                    margin-inline-start: auto;
                }
            }

            &_loggedIn {
                .MyAccountOverlay-Heading {
                    @include desktop {
                        display: none;
                    }
                }

                @include desktop {
                    width: 180px;
                    padding: 0;
                }
            }
        }

        h4 {
            font-size: 11px;
            font-weight: 400;
            margin-block-end: 6px;

            @include mobile {
                // font-size: 16px;
                margin-block-end: 7px;
            }
        }

        h3 {
            margin-block-start: 0;
        }
    }

    &-Additional {
        padding-block-start: 24px;

        section {
            display: flex;
            padding-block-start: 20px;

            &:first-of-type {
                padding-block-start: 0;
            }
        }

        &_state {
            &_createAccount {
                padding-block-start: 24px;

                @include mobile {
                    padding-block-start: 20px;
                }

                h4 {
                    margin: 0;
                    padding-inline-end: 0;
                }

                button {
                    width: initial;
                    margin-block: 0;
                    margin-inline-start: 5px;

                    &:hover,
                    &:focus {
                        margin-block: 0;
                    }
                }
            }

            &_signIn {
                section {
                    align-items: center;
                }

                button {
                    margin-inline-start: 5px;
                }
            }

            &_confirmEmail {
                padding-block-start: 0;
            }

            &_forgotPassword {
                section {
                    display: flex;

                    button {
                        width: initial;
                        margin-block-start: 0;
                        padding-inline-start: 5px;
                        font-weight: 600;

                        &:hover,
                        &:focus {
                            padding-inline-start: 5px;
                        }
                    }

                    h4 {
                        margin-block-end: 0;
                        align-self: center;
                    }

                    &:last-of-type {
                        align-items: center;
                        padding-block-start: 1px;
                    }
                }
            }

            &_forgotPasswordSuccess {
                padding-block-start: 0;

                h4 {
                    font-size: 12px;
                    line-height: 1.5;
                    padding-block-end: 17px;
                    font-weight: normal;
                    letter-spacing: normal;

                    @include mobile {
                        font-size: 14px;
                        padding-block-end: 20px;
                    }
                }
            }
        }
    }

    &-CloseButton {
        &::before,
        &::after {
            background-color: var(--header-color);
            content: "";
            height: 20px;
            position: absolute;
            inset-inline-end: 27px;
            inset-block-start: -13px;
            transform-origin: 50% 50%;
            width: 1px;
            z-index: 1;
        }

        &::before {
            transform: rotate(-45deg);
        }

        &::after {
            transform: rotate(45deg);
        }
    }

    &-ForgotPasswordButton {
        @include desktop {
            display: none;
        }
    }

    &-Buttons {
        margin-block-start: 6px;
        text-align: center;

        @include mobile {
            margin-block-start: 7px;
        }
    }

    &-Button {
        &_likeLink {
            font-size: 12px;
            margin-block-start: 14px;

            @include mobile {
                font-size: 13px;
                margin-block-start: 17px;
            }
        }
    }

    &-Checkbox {
        margin-block: 11px 24px;
    }
    
    .Combine-Auth { 
        display: flex;
        margin-block-start: 15px;
        margin-block-end: 10px;

        .MyAccountOverlay-ForgotPassword {
            margin-inline-start: auto;
        }
    }

    .MyAccountOverlay-ForgotPassword {
        // float: inline-end;
    }

    .MyAccountOverlay-Additional {
        text-align: center;
        padding-block-start: 30px;
        padding-block-end: 10px;
        
        @include mobile {
            padding-block-start: 15px;
        }

        section {
            justify-content: center;
            font-size: 14px;
    
            h4 {
                margin-block-end: 0;
                font-weight: 400;
                color: $default-primary-base-color;
            }

            button {
                font-weight: 600;
                font-size: 11px;
            }
        }

        &_state_forgotPasswordSuccess {
            text-align: start;
            padding-block-start: 0;
            
            .Popup-Heading {
                margin-block-end: 15px;
            }

            .Button {
                margin-block-start: 20px;
            }
        }

        &_state_createAccount {
            @include mobile {
                display: block;
            }
        }
    }

    .ResendOtp-Wrapper {
        display: flex;
        justify-content: flex-end;

        &_isLoginOtp {
            margin-block-start: 17px;
        }
        
        Button {
            width: auto;
            font-size: 12px;
            text-decoration: underline;
            padding-inline-start: 3px;
        }

        span {
            font-size: 12px;
            font-weight: 400;
        }
    }

    .SignInForm { 
        .ResendOtp-Wrapper {
            &_isLoginOtp {
                margin-block-start: 35px;
                justify-content: center;
                margin-block-end: 30px;
            }
        }
    }
}

.MyAccountSignIn {
    display: flex;
    flex-wrap: wrap;
    margin-block-start: 20px;
    margin-block-end: 0px;
    
    @include mobile {
        margin-block-start: 5px;
    }

    @media only screen and (max-width: 365px) {
        flex-direction: column;      
    }

    > div.MyAccountSignIn-Email,
    div.MyAccountSignIn-Otp {
        font-size: 14px;
        font-weight: 600;
        padding-inline-start: 25px;
        margin-inline-end: 30px;
        position: relative;
        color: $default-primary-base-color;

        @media screen and (max-width: 500px) {
            flex: 0 0 50%;
            margin-inline-end: 0;
            margin-block-end: 5px;
        }
        
        cursor: pointer;

        &::before {
            width: 16px;
            height: 16px;
            border: solid 1px  $default-primary-base-color;
            border-radius: 500%;
            content: "";
            position: absolute;
            inset-inline-start: 0;
            inset-block-start: 0;
        }

        &_isActive {
            &::after {
                width: 8px;
                height: 8px;
                background: $default-primary-base-color;
                border-radius: 500%;
                content: "";
                position: absolute;
                inset-block-start: 5px;
                inset-inline-start: 5px;
            }
        }
    }
}

.MyAccountOverlay-SignInWithOtpButton {
    margin-block-start: 20px;
}

.MyAccountOverlay {
    &-SignUpButton {
        margin-block-start: 10px;
    }

    &-Checkbox {
        margin-block-end: 5px;
    }
}
