/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccount {
    &-TabContent {
        @include desktop {
            border: 0;
            padding: 0;
            padding-block-start: 30px;
            width: calc(100% - 335px);
        }

        @include mobile {
            width: calc(100% - 30px);
            margin: 0 15px;
        }

        &_activeTab {
            &_my-account , &_address {
                .MyAccount-Heading {
                    display: none;
                }
            }

            &_my-orders, &_edit {
                .MyAccount-Heading {
                    @include mobile {
                        display: none;
                    }
                }
            }
        }
    }

    &-Wrapper {
        display: flex;
        flex-wrap: wrap;
       
        @include desktop {
            width: 95%;
            padding-block-end: 100px;
        }
        
        .Myaccount-Heading {
            grid-column: 1/-1;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-block-start: 30px;
            width: 100%;
            margin-block-end: 20px;

            @include mobile {
                margin-block-end: 30px;
            }

            .Image-Wrapper {
                width: 48px;
                height: 28px;
            }

            h2 {
                font-size: 32px;
                font-weight: 700;
                line-height: 40px;
                margin-block-start: 10px;
                letter-spacing: 1px;
                margin-block-end: 0;
                padding-block-end: 20px;

                @include mobile {
                    font-size: 16px;
                    line-height: 20px;
                }

                &::after {
                    content: '';
                    position: absolute;
                    inset-block-end: 0;
                    width: 40px;
                    height: 2px;
                    background-color: #E7E7E7;
                    inset-inline: 0;
                    margin: 0 auto;
                }
            }
        }

        .ExpandableContent {
            @include mobile {
                width: 100%;
            }
        }

        &_isDefault {
            .MyAccountTabList {
                @include desktop {
                    padding-block-start: 100px;
                }
            }
        }
    }

    &-Heading {
        font-size: 23px;
        font-weight: 700;
        margin-block-end: 25px;
        line-height: 1.2em;

        @include mobile {
            margin-block-end: 20px;
            font-size: 16px;
            margin-block-start: 20px;
            display: block;
        }
    }

    &-SubTitle {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        border-bottom: 1px solid $default-primary-base-color;
        // margin-block-end: 30px;
        padding-block-end: 25px;
        width: 100%;
        grid-column: 1/-1;

        @include mobile {
            padding-block-end: 12px;
            padding-block-start: 20px;
            margin-block-end: 20px;
        }
    }

    .MyAccount-TabContent_activeTab_my-wishlist {
        .MyAccount-Heading {
            @include desktop {
                font-size: 32px;
            }
        }
    }
}
