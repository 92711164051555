/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountOrderItemsTableRow {
    &-RowWrapper {
        display: flex;
        padding-block: 30px;
        border-bottom: 1px solid $border-color;
        align-items: flex-start;

        &::before {
            display: none;
        }
    }
    
    &-Image {
        width: 206px;
        margin-inline-end: 25px;
        
        @include mobile {
            width: 104px;
            margin-inline-end: 18px
        }

        .Image {
            position: relative;
            padding-block-end: 0;

            img {
                position: relative;
                object-fit: cover;
            }
        }
    }

    &-Name {
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
        color: $light-primary-color;
    }

    &-ProductDesc {
        dl {
            display: flex;
        }

        .price {
            font-weight: 700;
        }

        del {
            color: #ff0000;
            padding-inline-start: 5px;
            font-weight: 500;
        }
    }

    &-OptionLabel, &-OptionValue {
        margin-inline-start: 0;
        padding-inline-end: 5px;
        font-size: 10px;
        color: $light-primary-color;
        line-height: 16px;
        margin-block-end: 0;
    }

    &-ShippingAddress {
        margin-block-start: 30px;

        @include mobile {
            margin-block-start: 20px;
        }

        h4 {
            font-weight: 700;
            font-size: 12px;
        }
        
        p {
            margin-block-end: 0;
            font-size: 10px;
            color: $light-primary-color;
            line-height: 16px;
        }
    }

    &-Price {
        margin-block-start: 20px;

        @include mobile {
            margin-block-start: 16px;
        }
    }
    
    &-Actions {
        margin-block-start: 25px;
    }

    &-BuyAgain, &-Exchange, &-Return {
        @include button;

        border-width: 1px;
        border-radius: 4px;
        margin-inline-start: 5px;
        margin-inline-end: 15px;
        padding: 0 15px;
        cursor: pointer;

        &:hover {
            /* stylelint-disable-next-line declaration-no-important */
            padding: 0 15px !important;
        }

        @include mobile {
            margin-inline-end: 10px;
            margin-block-end: 10px;
            width: auto;
            padding: 0 10px;
            min-width: 100px;
        }
    }

    &-CancelWrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        gap: 20px;
        margin-block-start: 20px;

        @media screen and (max-width: 380px) {
            gap: 10px;
            margin-block-start: 10px;
        }

        .Field-Wrapper {
            @media screen and (max-width: 380px) {
                width: 100%;
            }
        }

        .Field {
            margin-block-start: 0;
        }

        button {
            width: auto;

            @media screen and (max-width: 380px) {
                width: 100%;
            }
        }
    }
}

.textred {
    color: red;
}

.cancelItemMsg {
    display: inline-block;

    @include mobile {
        font-size: 12px;
    }
}
