/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountAddressTable {
    @include mobile {
        margin-block-end: 20px;
    }

    &-Section {
        border: 1px solid $border-color;
        padding: 20px 40px 25px;
        text-align: start;
        border-radius: 4px;
        
        @include mobile {
            padding: 20px 25px 20px;
        }

        &_isSelected {
            border-color: $default-primary-base-color;

            &::before {
                content: '';
                position: absolute;
                inset-block-start: 20px;
                inset-inline-end: 20px;
                width: 11px;
                height: 6px;
                border-bottom: 1px solid $default-primary-base-color;
                border-left: 1px solid $default-primary-base-color;
                transform: rotate(-45deg);
            }
        }

        h4 {
            font-size: 14px;
            font-weight: 600;
            margin-block-end: 10px;
        } 

        p {
            margin-block-end: 0;
            font-size: 12px;
            line-height: 20px;
            
            strong {
                margin-block-end: 8px;
            }
        }
    }

    .MyAddressAction {
        &-Group {
            margin-block-start: 14px;
            
            @include mobile {
                display: flex;
            }

            .Button {
                border: 0;
                padding: 0;
                height: auto;
                color: $black;
                padding-inline-end: 20px;
                font-size: 12px;
                cursor: pointer;

                @include mobile {
                    width: auto;
                }

                &[disabled] {
                    @include button-disabled();
                }

                & + button {
                    margin-block-start: 0;
                }
            }
        }
    }

    &_isDeleteNotAllowed {
        .MyAccountAddressTable-Section {
            border-color: $default-primary-base-color;
        }

        &::before {
            content: '';
            position: absolute;
            inset-block-start: 20px;
            inset-inline-end: 20px;
            width: 11px;
            height: 6px;
            border-bottom: 1px solid $default-primary-base-color;
            border-left: 1px solid $default-primary-base-color;
            transform: rotate(-45deg);
        }
    }
}
