/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 .MyAccountNewReturnItemSelect {
     border-bottom: solid 1px $border-color;
    &-Title {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        border-bottom: solid 1px $border-color;
        padding-bottom: 20px;


        @include before-desktop {
            margin-top: 20px;
        }
    }

    &-ItemWrapper {
        display: flex;
        margin: 20px 0;
        flex-wrap: wrap;
        
        @include mobile {
            margin: 15px 0;
        }

        @include before-desktop {
            flex-direction: column;
        }

        .CartItem-Wrapper {
            order: 2;
        }

        .CartItem-Content{
            .CartItem-Heading{
                font-size: 14px;
            }
        }
    }

    &-ItemInnerWrapper {
        display: flex;
        padding: 0px 0;
        width: 100%;
        
        &:last-child {
            padding-block-end: 0;
        }

        @include before-desktop {
            // flex-direction: column;
        }

        > div {
            flex-grow: 0;
            flex-shrink: 0;
        }
    }

    &-ItemField {
        flex: 1;
        flex-basis: 30px;
        flex-grow: 0;
        flex-shrink: 0;
        order: 1;
        margin: 0;
        height: max-content;

        @include desktop {
            // margin-top: 10px;
        }

        &_isDisabled {
            &[type='checkbox']:disabled + label {
                &:last-of-type {
                    cursor: not-allowed;
                    border: 1px solid var(--input-border-color);
                }
            }
        }

        label {
            font-weight: normal;
        }

        label:last-of-type {
            // @include mobile {
            //     position: absolute;
            //     left: 0;
            //     top: 50%;
            //     transform: translateY(-50%);
            // }
        }
    }

    &-ReasonBlockWrapper {
        margin-block-start: 10px;
    }

    &-SelectWrapper {
        &:not(:first-of-type) {
            margin-top: 20px;
            max-width: 450px;
            width: 450px;
            
            @media only screen and (max-width: 600px) {
                max-width: 100%;
                width: 100%;
            }
        }
    }

    &-BankTransferWrapper {
        &:not(:first-of-type) {
            margin-top: 25px;
            max-width: 100%;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;
            @media only screen and (max-width: 600px) {
                max-width: 100%;
                width: 100%;
            }
        }
        .MyAccountNewReturnItemSelect-TextInputWrapper{
            .MyAccountNewReturnItemSelect-FieldInput{
                width: 100%;
            }
        }
    }
    

    &-TextInputWrapper {
        margin: 10px 0;
        max-width: 49%;
        width: 49%;
        @media only screen and (max-width: 600px) {
            max-width: 100%;
            width: 100%;
        }
    }

    &-SelectTitle {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;

        @include desktop {
            font-size: 16px;
        }
    }

    &-SelectInput {
        margin-top: 5px;

        .Field-Select {
            @include before-desktop {
                font-size: var(--input-mobile-size);
            }
        }
    }

    &-FieldInput {
        margin-top: 5px;

        @include before-desktop {
            font-size: var(--input-mobile-size);
        }
    }

    &-QtyBlockWrapper {
        font-size: 11px;

        .Field_type_number {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin: 0 10px;
            border: solid 1px $default-primary-base-color;

            [type='number'] ~ button{
                border: none;
                width: 30px;
                height: 46px;
            }

            [type='number']{
                font-size: 16px;
                color: $default-primary-base-color;
                width: 28px;
                min-width: 28px;
            }

            &.Field_hasError {
                margin-bottom: 10px;

                .Field-Message {
                    position: absolute;
                }

                button {
                    &:last-of-type {
                        bottom: 0;
                    }
                }
            }

            input {
                font-size: var(--font-size);
            }

            button {
                @include before-desktop {
                    width: unset;
                    font-size: 1.3rem;
                }
            }
        }
        .Field  {
            margin-block-start: 5px;
            input {
                width: 72px;
                text-align: center;
            }
        }

        .Input-Wrapper {
            display: flex;
            align-items: center;

            .TotalQty {
                font-size: 14px; 
                margin-inline-start: 18px;  
            }
        }
    }

    &-ItemImagePlaceholder {
        &::after {
            height: 160px;
            display: inline-block;
            width: 110px;
            margin: 25px 1rem 25px 0;
        }
    }

    &-ReasonBlockRuleTitle,
    &-ReasonBlockRule {
        font-size: var(--font-size);
        display: block;
    }

    &-ReasonBlock{
        > div{
            margin-bottom: 10px;
        }
    }

    &-ReasonBlockRuleTitle {
        font-size: 17px;
        margin-bottom: 10px;
    }

    .ReturnItem {
        &-Wrapper{
            order: 2;
            display: flex;
            flex-wrap: wrap;
            h5{
                margin-left: 17px;
                line-height: 24px;
                font-size: 14px;
                font-weight: 400;
            }
        }

        &-Picture {
            width: 117px;
            height: 150px;
            align-self: flex-start;

            img {
                object-position: top;
            }

            &_isMobileLayout {
                width: 117px;
                height: 175px;
            }
        }

        &-Details {
            padding-left: 14px;
        }

        &-Heading {
            width: 100%;
            overflow: inherit;
            text-overflow: inherit;
            white-space: inherit;
            font-size: 12px;
            font-weight: 600;
            color: #0B1F3F;
            margin-bottom: 2px;
            line-height: 16px;
        }

        &-Attributes {
            color: #0B1F3F;
            font-size: 12x;
            line-height: 16px;
        }
    }
}