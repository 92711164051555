/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

.MyAccountDashboard {
    &-DefaultAddress {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        margin-block: 20px 50px;

        @include mobile {
            margin-block-end: 10px;
        }

        .MyAccountReturnAddress {
            h3{
                margin-top: 0;
            }
            li{
                margin-bottom: 0;
            }
            ul {
                li {
                    font-size: 12px;

                    &.LastName {
                        display: none;
                    }
                }
            }
        }
    }
    &-AddressTitle {
        margin-block-end: 10px;
        font-weight: 600;
    }

    &-Name {
        font-weight: 700;
        font-size: 12px;
    }
}