/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountMyOrders {
    grid-gap: 20px;
    
    &-Msg {
        font-size: 16px;
        margin-block-end: 0;
        
        @include mobile {
            padding-block-start: 20px;
            text-align: center;
            color: $light-primary-color;
        }
    }

    &-NoOrders {
        p {
            padding: 30px 0 15px;

            @include desktop {
                font-size: 16px;
            }
        }

        .Button {
            @include desktop {
                min-width: 212px;
            }
        }
    }

    .ProductTabs {
        &-Wrapper {
            padding-inline: 0;

            @include mobile {
                margin-block-end: 50px;
            }
        }

        .ProductTab {
            &-Item {
                color: $default-primary-base-color;
                text-decoration: none;
                letter-spacing: 1/2px;
                padding: 20px 0;

                @include desktop {
                    margin-inline-end: 50px;
                }

                &_isActive {
                    background-color: transparent;

                    &::after {
                        content: '';
                        position: absolute;
                        inset-block-end: 0;
                        width: 100%;
                        height: 2px;
                        background-color: $default-primary-base-color;
                        inset-inline-start: 0;
                    }
                }
            }

            a {
                font-size: 16px;
            }

            &-Button {
                color: $default-primary-base-color;
                text-decoration: none;
                letter-spacing: 0.25;
                padding: 0;
                font-size: 16px;

                @include mobile {
                    width: 100%;
                }
                
                a {
                    font-size: inherit
                }
            }
        }
    }

    .MyAccount-Heading {
        @include mobile {
            display: none;
        }
    }
} 


.MyAccountMyOrders-Table + nav .Pagination {
    display: flex;
    margin-block-start: 0;
    margin-block-end: 40px;
    min-height: inherit;
}
