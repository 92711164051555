/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountAddressPopup {
    .FieldForm {
        &-Fields {
            @include desktop {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-column-gap: 16px;
            }

            .Field {
                margin-block-start: 5px;
            }

            .Field-Wrapper {
                &:nth-child(1) , &_type_checkbox {
                    grid-column-start: 1;
                    grid-column-end: 3;
                }

                // &_type_checkbox {
                //     margin-block-start: 10px;
                // }

                &:nth-last-of-type(2) {
                    margin-block-start: 15px;
                }
            }

            .FieldGroup {
                &-Wrapper {
                    &_country {
                        display: none;
                    }

                    &_oddAddresses {
                        grid-column-start: 1;
                        grid-column-end: 3;
                    }
                }
            }
        }

        &-Body {
            .lastName {
                display: none;
            }
        }
    }

    .MyAccount-Button {
        margin: 20px 0;
        
        @include desktop {
            width: 50%;
        }
    }

    .Button {
        margin-inline-end: 10px;
        
        @include mobile {
            width: auto;
        }
    }
}
