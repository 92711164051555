/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountOrder {
    &-CreationDate {
        margin-block-end: 0;
    }

    &-OrderId {
        margin-block-start: 0;
        margin-block-end: 6px;
    }

    &-OrderStatus {
        font-weight: 700;
        border: 0;
        margin-block-start: 0;
        padding: 0;
        text-transform: none;
        font-size: 14px;
        color: $black;
        width: 100%;
    }

    &-Date, &-ID {
        font-size: 12px;
        display: block;
        font-weight: 600;
        color: $light-primary-color;
        line-height: 20px;
    }

    &-Detail {
        .MyAccount {
            &-SubTitle {
                padding-block-end: 12px;
                border-color: $border-color;
                font-weight: 700;
                line-height: 21px;

                @include mobile {
                    margin-block-end: 0;
                    padding-block-start: 10px;
                }
            }
        }
    }

    &-Topdiv {
        padding-block-start: 15px;
        
        @include desktop {
            margin-block-start: 15px;
            border-top: 1px solid $border-color;
            padding-block-start: 24px;
            margin-block-end: 18px;
        }

        .Button {
            position: absolute;
            inset-inline-end: 0;
            z-index: 2;
            inset-block-end: -18px;
            min-width: 137px;
            width: 137px;

            @include mobile {
                inset-block-end: -28px;
            }
        }
    }
}
