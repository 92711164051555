/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountPreferenceForm {
    .FieldGroup {
        display: flex;
        font-size: 12px;
        // justify-content: space-between;
        border-left: 0;
        flex-wrap: wrap;
        padding-inline-start: 0;

        @include mobile {
            flex-wrap: wrap;
        }
    }

    &-ShopFor, &-YouInto {
        h3 {
            font-size: 16px;
            font-weight: 700;
            /* stylelint-disable-next-line number-max-precision */
            letter-spacing: 0.15px;
            margin-block-end: 00px;
            line-height: 1;
            margin-block-start: 24px;
            padding-block-start: 10px;
            
            @include mobile {
                font-size: 14px;
                margin-block-end: 10px;
            }
        }     
    }

    .Field {
        &-CheckboxLabel {
            width: 125px;
        }

        &-Wrapper {
            flex-basis: 25%;

            @include mobile {
                flex-basis: 33.33%;
            }

            .Field {
                margin-block-start: 20px;
            }
        }
    }

    .FieldForm {
        &-Legend {
            font-weight: 600;
            margin-block-start: 20px;

            @include desktop {
                margin-block-start: 72px;
            }
        }
    }

    .Preferences {
        &-Form {
            @include desktop {
                width: 80%;
                margin-inline: auto;
            }

            @include mobile {
                margin-block-end: 30px;
            }
        }
    }
}
