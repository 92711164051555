/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

.MyAccountGuestReturns {
    padding: 30px 0;

    .MyAccount-SubTitle{
        padding-bottom: 12px;
        border-color: #d0d0d0;
        font-weight: 700;
        line-height: 21px;
    }

    &-Loading{
        padding: 20px 0;
        font-size: 17px;
        font-weight: 700;
    }

    .MyAccountGuestReturns {
        &-Heading {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 0;
            h1{
                font-size: 23px;
                font-weight: 700;
                line-height: 23px;
            }

            button {
                cursor: pointer;
                padding: 15px;
                border: 1px solid black;
                background-color: black;
                color: white;
                letter-spacing: 1.5px;
                font-weight: 700;
                border-radius: 3.5px;
            }

            @include mobile {
                button {
                    letter-spacing: 0.5px;
                    padding: 5px;
                }
            }
        }
    }

}
