/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountOrderTableRow {
    height: auto;
    display: flex;
    // justify-content: space-between;
    border-bottom: 1px solid $border-color;
    padding: 28px 0;
    
    @include mobile {
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    &:hover {
        background-color: transparent;
    }

    &::before {
        display: none;
    }
    
    & > div {
        // flex: 1 auto;
    }

    .Image-Wrapper {
        @include desktop {
            width: 25%;
        }

        .Image {
            width: 56px;
            padding-block-end: 0;

            @include mobile {
                margin-inline-end: 5px;
            }
        }

        span {
            font-size: 12px;
        }

        .more {
            @include mobile {
                display: block;
            }
        }
    }

    .Order {
        &-Info {
            display: flex;
            flex-direction: column;
            font-size: 14px;
            color: $default-primary-base-color;
            
            @include desktop {
                width: 45%;
            }

            @include mobile {
                margin-block-end: 15px;
                padding-inline-start: 15px;
            }

            strong {
                font-weight: 700;
                color: $default-primary-base-color;
            }

            span {
                color: $default-primary-base-color;
            }
        }
    }

    .Status {
        display: flex;
        align-items: center;
        width: 30%;
        position: relative;
        
        @include mobile {
            width: 100%;
            margin-block-start: 35px;
        }

        &::after {
            content: '';
            position: absolute;
        }

        .Icon {
            width: 19px;
            height: 15px;
            margin-inline-end: 10px;
        }

        span {
            color: $btn-primary-color;
        }

        strong {
            font-weight: 700;
            color: $btn-primary-color;
        }

        .ChevronIcon {
            position: absolute;
            fill: $btn-primary-color;
            width: 10px;
            inset-inline-end: 20px;

            @include mobile {
                inset-inline-end: 0;
            }
        }
    }
}
